<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import IconHeart from '@/components/icons/IconHeart.vue'
import type { PaymentType } from '@/types'
import { getFormattedAmount } from '@/utils/currency'

const props = defineProps<{
  amounts: number[]
  preselectedAmount: number
  closestAmount: number
  paymentType: PaymentType
  modelValue?: number
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: number): void
}>()

const { t } = useI18n()
const language = inject('language') as string

const ruler = ref<HTMLDivElement>()
const keyboardFocusedAmount = ref<number>(0)

const selectedAmountIndex = computed(() =>
  props.modelValue ? props.amounts.indexOf(props.modelValue) : -1
)

const indexToFocus = computed(() => {
  const amountToFocus =
    keyboardFocusedAmount.value ||
    props.closestAmount ||
    props.modelValue ||
    props.preselectedAmount
  return props.amounts.indexOf(amountToFocus)
})

const rulerTransformStyle = computed(() => {
  let step = 0

  if (indexToFocus.value <= 2) {
    // No X translation before 4th item
    return {}
  } else if (indexToFocus.value >= props.amounts.length - 2) {
    // No more X translation after last 4 items
    step = props.amounts.length - 5
  } else {
    step = indexToFocus.value - 2
  }

  const translateX = `translateX(calc(-${step} * var(--ruler-amount-width) + ${step * 0.5}em))`
  return { transform: translateX }
})

function onClickOnAmount(amount: number) {
  keyboardFocusedAmount.value = 0
  emit('update:modelValue', amount)
}

function onKeyboardFocusOnAmount(amount: number) {
  keyboardFocusedAmount.value = amount
  ruler.value?.parentElement?.scroll(0, 0)
}

function onBlurFromAmount() {
  if (!ruler.value?.contains(document.activeElement)) {
    keyboardFocusedAmount.value = 0
  }
}
</script>

<template>
  <div
    ref="ruler"
    class="ruler"
    :class="{ 'ruler-xl': amounts.length >= 5 }"
    :style="rulerTransformStyle"
  >
    <div
      v-if="modelValue"
      v-show="selectedAmountIndex > -1"
      class="ruler-selector"
      :style="{ '--selected-amount-index': selectedAmountIndex }"
    ></div>
    <button
      v-for="amount in amounts"
      :id="`btn-${amount}-${paymentType}`"
      :key="amount"
      class="ruler-amount"
      :class="{ selected: amount === modelValue }"
      type="button"
      :aria-describedby="`equivalence-text-${amount}-${paymentType} popular-equivalence-${paymentType}`"
      :aria-pressed="amount === modelValue"
      @mousedown="$event.preventDefault()"
      @focus="onKeyboardFocusOnAmount(amount)"
      @click="onClickOnAmount(amount)"
      @blur="onBlurFromAmount"
    >
      <div v-if="amount === preselectedAmount">
        <div class="ruler-popular-label text-secondary">
          <span :id="`popular-equivalence-${paymentType}`">{{ t('popular') }}&nbsp;</span>
          <IconHeart :aria-labelledby="`popular-equivalence-${paymentType}`" size="12" />
        </div>
      </div>
      {{ getFormattedAmount(amount, language) }}
    </button>
  </div>
</template>
