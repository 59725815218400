<template>
  <div
    :class="{
      'was-validated': validated,
      'position-relative': autocompleteOpen,
      'form-floating': floatingLabel,
      [props.class || 'col mb-2']: true,
    }"
  >
    <component
      :is="type === 'multiline' ? 'textarea' : 'input'"
      :id="$attrs.id || $attrs.name"
      ref="el"
      v-bind="$attrs"
      :value="modelValue"
      :type="
        type === 'multiline'
          ? null
          : ['singleline', 'date', 'birthdate'].includes(type)
            ? 'text'
            : type
      "
      :placeholder="`${label}${required ? ' *' : ''}`"
      :aria-label="label"
      :required="required"
      class="form-control"
      :class="{
        'has-warning is-invalid': warning || suggestion,
        'has-open-autocomplete': autocompleteOpen,
      }"
      @input="onInput"
      @blur="onBlur"
      @focus="validated = false"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.esc.prevent="autocompleteOpen && closeAutocomplete()"
      @keydown.enter="onEnter"
    />
    <ul
      v-if="autocompleteItems && autocompleteItems.length > 0"
      v-show="autocompleteOpen"
      class="autocomplete-items"
      @mousedown.prevent
    >
      <li
        v-for="(item, index) in autocompleteItems"
        :key="item.value"
        class="autocomplete-item"
        :class="{ 'is-active': index === autocompleteSelectedIndex }"
        @click="onAutocompleteSelect(item.value)"
      >
        {{ item.label }}
      </li>
    </ul>
    <label v-if="floatingLabel" :for="($attrs.id || $attrs.name) as string">
      <span class="placeholder-label">{{ `${label}${required ? ' *' : ''}` }}</span>
      <span class="floating-label">{{ label }}</span>
    </label>
    <div v-if="warning" class="invalid-feedback">{{ warning }}</div>
    <div v-if="suggestion" class="invalid-feedback">
      <div>{{ t('did_you_mean', { suggestion: suggestion }) }}</div>
      <button class="btn btn-link btn-sm p-0" @click="applySuggestion">
        {{ t('fix') }}
      </button>
    </div>
  </div>
  <div v-if="!props.class" class="w-100" />
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { EmailVerifier } from '@/plugins/email-verification'
import type { FieldType, SelectChoice } from '@/types'
import { countSpaces } from '@/utils/text'

const { t } = useI18n()

const emailVerifier = inject('emailVerifier') as EmailVerifier | null

const props = defineProps<{
  required: boolean
  type: FieldType
  label: string
  modelValue?: string
  class?: string
  validators?: Function[]
  formatter?: Function

  noFloatingLabel?: boolean
  autocompleteSearch?: Function
  autocompleteApply?: Function
}>()
defineOptions({ inheritAttrs: false })

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string): void
}>()

const validated = ref(false)
const el = ref<HTMLInputElement | HTMLTextAreaElement | null>(null)

const warning = ref<string | null>(null)
const suggestion = ref<string | null>(null)

const floatingLabel = computed(() => !props.noFloatingLabel && props.type !== 'multiline')
const autocompleteOpen = ref(false)
const autocompleteItems = ref<SelectChoice[]>([])

const isTypeDate = computed<boolean>(() => ['date', 'birthdate'].includes(props.type))

async function validateEmail() {
  if (emailVerifier && props.type === 'email' && el.value?.value && el.value.validity.valid) {
    const report = await emailVerifier.verifyEmail(el.value.value)
    if (report.suggestion) {
      suggestion.value = report.suggestion
    } else if (!report.valid) {
      warning.value = t('invalid_email')
    }
  }
}

function applySuggestion() {
  if (el.value && suggestion.value) {
    emit('update:modelValue', suggestion.value)
    suggestion.value = null
    el.value.focus()
  }
}

function onBlur() {
  validated.value = props.required || !!el.value?.value
  el.value?.checkValidity()

  validateEmail()
  closeAutocomplete()
}

onMounted(() => {
  props.modelValue && onBlur()
  validateValue()
})

async function onInput($event: InputEvent) {
  warning.value = null
  suggestion.value = null

  validateValue()
  formatValue()

  emit('update:modelValue', ($event.target as HTMLInputElement).value)

  if (props.autocompleteSearch && el.value) {
    autocompleteItems.value = await props.autocompleteSearch(el.value.value)
    autocompleteOpen.value = autocompleteItems.value.length > 0
  }
}

function validateValue() {
  let error = ''
  if (isTypeDate.value && el.value?.value) {
    // Check valid date if type is date
    const [day, month, year] = el.value.value.split('/').map((v) => parseInt(v))
    const date = new Date(year, month - 1, day)
    const thisYear = new Date().getFullYear()
    if (
      date.toString() === 'Invalid Date' ||
      year !== date.getFullYear() ||
      month !== date.getMonth() + 1 ||
      day !== date.getDate()
    ) {
      error = t('invalid_date')
    } else if (props.type === 'birthdate' && (year < 1900 || year > thisYear)) {
      error = t('invalid_year')
    }
  }

  // Validate against validators
  if (props.validators?.length && el.value) {
    props.validators.some((validator) => {
      const e = validator(el.value?.value, t)
      if (e) {
        error = e
        return true
      }
      return false
    })
  }

  el.value?.setCustomValidity(error)
}

function formatValue() {
  if (!props.formatter) {
    return
  }

  const input = el.value as HTMLInputElement | HTMLTextAreaElement
  const position = input.selectionEnd || 0
  const previousValue = input.value

  input.value = props.formatter(input.value)

  // Update cursor position
  if (position !== input.value.length) {
    const beforeCaret = previousValue.substring(0, position)
    const countPrevious = countSpaces(beforeCaret)
    const countCurrent = countSpaces(props.formatter(beforeCaret))
    input.selectionEnd = position + (countCurrent - countPrevious)
  }
}

// Autocomplete handlers

function onAutocompleteSelect(itemId: string) {
  props.autocompleteApply?.(itemId)
  closeAutocomplete()
}

const autocompleteSelectedIndex = ref(-1)

function onArrowDown() {
  if (
    autocompleteOpen.value &&
    autocompleteSelectedIndex.value < autocompleteItems.value.length - 1
  ) {
    autocompleteSelectedIndex.value++
  }
}

function onArrowUp() {
  if (autocompleteSelectedIndex.value >= 0) {
    autocompleteSelectedIndex.value--
  }
}

function onEnter($event: KeyboardEvent) {
  if (autocompleteSelectedIndex.value >= 0) {
    $event.preventDefault()
    onAutocompleteSelect(autocompleteItems.value[autocompleteSelectedIndex.value].value)
  }
}

function closeAutocomplete() {
  autocompleteOpen.value = false
  autocompleteSelectedIndex.value = -1
}
</script>
