<script setup lang="ts">
import { computed, inject } from 'vue'

import type { EquivalenceGrid, PaymentType } from '@/types'
import { getFormattedAmount } from '@/utils/currency'

const props = defineProps<{
  grid: EquivalenceGrid
  closestAmount: number
  paymentType: PaymentType
  modelValue?: number
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: number): void
}>()

const language = inject('language') as string

const selectedCardIndex = computed(() => {
  let amountToFocus = props.modelValue || props.closestAmount || props.grid.popularAmount

  return props.grid.equivalences.findIndex((item) => item.amount === amountToFocus)
})

const carouselTransformStyle = computed(() => {
  // Size of all previous cards + size of the gap between them
  const translateX = `translateX(calc(-${selectedCardIndex.value} * var(--equivalence-card-width) - ${selectedCardIndex.value}em))`
  return { transform: translateX }
})
</script>

<template>
  <div
    class="equivalence-carousel"
    :class="{ 'opacity-50': modelValue === 0 }"
    :style="carouselTransformStyle"
  >
    <div
      v-for="equivalence in grid.equivalences"
      :key="equivalence.amount"
      class="card equivalence-card"
      :class="{
        selected: equivalence.amount === modelValue,
        'equivalence-card-with-image': grid.format === 'image',
        'equivalence-card-text-only': grid.format === 'text',
      }"
      @click="emit('update:modelValue', equivalence.amount)"
    >
      <div class="card-body">
        <img
          v-if="grid.format === 'image'"
          :src="equivalence.image_url"
          class="card-img-top"
          :alt="equivalence.image_alt"
        />
        <div v-else class="card-title">
          {{ getFormattedAmount(equivalence.amount, language) }}
        </div>
        <div :id="`equivalence-text-${equivalence.amount}-${paymentType}`" class="card-subtitle">
          {{ equivalence.description }}
        </div>
      </div>
    </div>
  </div>
</template>
