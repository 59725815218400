export const getFormattedAmount = (
  amount: number,
  language: string,
  currency: string = 'EUR',
  minDigits: number = 0,
  maxDigits: number = 0
) =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }).format(amount)
