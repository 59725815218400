<template>
  <span class="d-block">{{ title }}</span>
  <span v-if="subtitle.length" class="text-black-50">{{ subtitle.join('|') }}</span>
</template>

<script setup lang="ts">
/**
 * A simple component to make option label display on 2 lines, if needed
 */
const { label } = defineProps<{
  label: string
}>()
const [title, ...subtitle] = label.split('|')
</script>
