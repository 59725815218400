<script setup lang="ts">
import { computed } from 'vue'

import AmountRuler from '@/components/inputs/AmountRuler.vue'
import EquivalenceCarousel from '@/components/inputs/EquivalenceCarousel.vue'
import type { EquivalenceGrid, PaymentType } from '@/types'

const props = defineProps<{
  type: PaymentType
  grid: EquivalenceGrid
  modelValue?: number
  customAmount?: number | null
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: number): void
}>()

const closestLowerAmount = computed(() => {
  if (!props.customAmount) {
    return 0
  }

  const sortedAmounts = props.grid.equivalences
    .map((equivalence) => equivalence.amount)
    .sort((a, b) => a - b)

  return (
    sortedAmounts.filter((amount) => amount <= props.customAmount!).pop() ||
    sortedAmounts.shift() ||
    0
  )
})

const selectedEquivalenceIndex = computed({
  get() {
    return props.grid.equivalences.findIndex((item) => item.amount === props.modelValue)
  },
  set(index: number) {
    if (0 <= index && index < props.grid.equivalences.length) {
      emit('update:modelValue', props.grid.equivalences[index].amount)
    }
  },
})

function onTouchStart(event: TouchEvent) {
  if (event.changedTouches.length !== 1) {
    return
  }

  const startX = event.changedTouches[0].clientX
  const startY = event.changedTouches[0].clientY

  addEventListener('touchend', (event: TouchEvent) => onTouchEnd(event, startX, startY), {
    once: true,
  })
}

function onTouchEnd(event: TouchEvent, startX: number, startY: number) {
  if (event.changedTouches.length !== 1) {
    return
  }

  const endX = event.changedTouches[0].clientX
  const endY = event.changedTouches[0].clientY

  if (Math.abs(startX - endX) < Math.abs(startY - endY)) {
    return
  }

  if (startX < endX && selectedEquivalenceIndex.value - 1 >= 0) {
    selectedEquivalenceIndex.value = selectedEquivalenceIndex.value - 1
  } else if (startX > endX) {
    selectedEquivalenceIndex.value = selectedEquivalenceIndex.value + 1
  }
}
</script>

<template>
  <div class="equivalence-grid">
    <AmountRuler
      :amounts="grid.equivalences.map((equiv) => equiv.amount)"
      :preselected-amount="grid.popularAmount"
      :closest-amount="closestLowerAmount"
      :payment-type="type"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      @touchstart="onTouchStart"
    />
    <EquivalenceCarousel
      :grid="grid"
      :closest-amount="closestLowerAmount"
      :payment-type="type"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      @touchstart="onTouchStart"
    />
    <div v-if="grid.infoText" class="equivalence-grid-info">
      {{ grid.infoText }}
    </div>
  </div>
</template>
