import type { EnabledPaymentType, PaymentType, PushRegularWidgetOptions } from '@/types'
import { formatMMDD } from '@/utils/date'

export function isPushRegularWidgetApplicable(
  amount: number,
  enabledPaymentTypes: EnabledPaymentType,
  currentType: PaymentType,
  widgetOptions: PushRegularWidgetOptions
) {
  // Not applicable if regular payment not activated or payment already selected
  if (enabledPaymentTypes === 'one-off' || currentType === 'regular') {
    return false
  }

  // Not applicable if amount not within min and max trigger values
  const minAmount = widgetOptions?.min_trigger_amount || 0
  const maxAmount = widgetOptions?.max_trigger_amount || Infinity
  if (amount < minAmount || amount > maxAmount) {
    return false
  }

  // Not applicable if no trigger period or none including today
  const now = formatMMDD(Date.now())
  if (
    widgetOptions?.trigger_periods.length &&
    !widgetOptions?.trigger_periods.some((period) => {
      return (!period.start || period.start <= now) && (!period.end || period.end >= now)
    })
  ) {
    return false
  }

  return true
}
