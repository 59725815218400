import type { FieldConfig } from '@/types'
import { validateBERegistrationNumber } from '@/utils/input-validators'

const FORM_FIELD_EXTRA_CONFIG_MAPPING: { [fieldName: string]: any } = {
  zip: { class: 'col-5 pe-2' },
  civility: { class: 'col-5 pe-2' },
  be_registration_number: { validators: [validateBERegistrationNumber] },
}

export function attachFieldsExtraConfig(
  fields: FieldConfig[],
  extraConfig: { [fieldName: string]: any } = {}
) {
  return fields.map((field: FieldConfig) => ({
    ...field,
    ...(FORM_FIELD_EXTRA_CONFIG_MAPPING[field.name] || {}),
    ...(extraConfig[field.name] || {}),
  }))
}
